import alert from './alert'
import breadcrumbs from './breadcrumbs'
import button from './button'
import iconButton from './icon-button'
import input from './input'
import textarea from './textarea'
import typography from './typography'
import menu from './menu'
import { tabs, tabsHeader, tabsBody, tab, tabPanel } from './tabs'

const theme = {
  alert,
  button,
  breadcrumbs,
  iconButton,
  input,
  textarea,
  typography,
  tabs,
  tabsHeader,
  tabsBody,
  tab,
  tabPanel,
  menu,
}

export default theme
